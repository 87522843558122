<template>
  <div class="chat" :class="{ mention: showMention && room.mention }">
    <div ref="chatContainer" class="chat-content">
      <div :key="i" v-for="(item, i) in messages">
        <div v-if="isFirstMessage(i)" class="start-conversation">
          {{ title }}
        </div>
        <div v-if="showDate(i)" class="start-conversation-date">
          <hr />
          <div class="date">{{ getDate(item.date) }}</div>
        </div>
        <div
          v-if="showMessage(i)"
          class="message-content"
          :class="{
            mine:
              item.owner.id === currentUserId ||
              item.owner.username === mainUserName,
            small: !!item.type,
            'info-noti': isInfoNotification(i),
          }"
        >
          <div class="message">
            <div class="full-name" v-if="showFullName(i)">
              {{ item.owner.name }}
            </div>
            <div v-html="getTextMessage(item)" />
            <span class="time">{{ getTime(item.date) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!disabled && showPresavedMessages && preservedMessages.length"
      class="presaved-messages"
    >
      <div class="pm-container">
        <div
          :key="i"
          v-for="(message, i) in preservedMessages"
          class="presaved-message"
          @click="presavedMessageSelect(isEnglish ? message.nameEN : message.name)"
        >
          {{ isEnglish ? message.nameEN : message.name }}
        </div>
      </div>
    </div>
    <div v-if="disabled" class="chat-typing-members">
      <small v-if="membersTyping.length">
        {{ $t('isWriting', { names: typingNames }) }}
      </small>
    </div>

    <div v-if="!disabled" class="chat-form">
      <div class="row h-100 mx-0">
        <div class="col-sm-12" style="height: 0;">
          <small v-if="membersTyping.length">
            {{ $t('isWriting', { names: typingNames }) }}
          </small>
        </div>
        <div class="col-sm-9 d-flex align-items-center col-chat-input">
          <Mentionable
            ref="mentionable"
            :keys="['@']"
            :items="members"
            offset="6"
            insert-space
            @open="onOpen"
          >
            <textarea
              ref="textarea"
              :value="message"
              :placeholder="`${$t('writeYourMessage')}...`"
              class="textarea"
              rows="1"
              :disabled="room.loading"
              @input="onInputChange"
              @keydown.enter.prevent="handleEnter"
              @focus="onFocus()"
            ></textarea>
            <template #no-result>
              <div class="mention-no-result">
                {{ $t(room.loadingMembers ? 'loading' : 'noResultsFound') }}
              </div>
            </template>
            <template #item-@="{ item }">
              <div class="user">
                {{ item.text }}
              </div>
            </template>
          </Mentionable>
        </div>
        <div
          class="col-sm-3 d-flex justify-content-center align-items-center col-chat-submit"
        >
          <button
            class="btn btn-primary btn-sm"
            :disabled="room.loading"
            @click="onSubmit({ force: true })"
          >
            <i class="fad fa-paper-plane"></i> {{ $t('send') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { modulesName } from '@/store';
import moment from 'moment';
import RocketChatService from '@/services/RocketChatService';
import { getChatPresavedMessages as _getChatPresavedMessages } from '@/services/CommunicationService';
import { Mentionable } from 'vue-mention';

export default {
  name: 'Chat',
  components: {
    Mentionable,
  },
  props: {
    room: {
      type: Object,
      default: () => ({}),
    },
    startMessage: {
      type: String,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideUserEntries: {
      type: Array,
      default: () => [],
    },
    hideMentions: {
      type: Array,
      default: () => [],
    },
    mainUserName: {
      type: String,
      default: null,
    },
    showMention: {
      type: Boolean,
      default: false,
    },
    showInfoNotifications: {
      type: Array,
      default: () => [],
    },
    showPresavedMessages: {
      type: Boolean,
      default: false,
    },
    language: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      currentUserId: RocketChatService.userId,
      message: '',
      members: [],
      selectingMember: false,
      preservedMessages: [],
    };
  },
  computed: {
    ...mapState(modulesName.userModuleName, ['showInternalChat']),
    ...mapGetters(modulesName.userModuleName, ['profile', 'currentActiveLocation']),
    ...mapState(modulesName.appModuleName, ['hubLoaded']),
    isEnglish() {
      return this.language == 'en';
    },
    messages() {
      const value = [...this.room.messages];
      return value.reverse();
    },
    title() {
      return this.startMessage != null
        ? this.startMessage
        : this.$t('startOfConversation');
    },
    currentUsername() {
      if (!this.profile) {
        return this.room.members.find((x) => x.userId == this.currentUserId)
          ?.username;
      }
      return this.room.members.find((x) => x.userId == this.profile?.id)
        ?.username;
    },
    membersTyping() {
      return this.room.members.filter(
        (x) =>
          x.typing &&
          x.userId != this.profile?.id &&
          x.userId != this.currentUserId,
      );
    },
    typingNames() {
      return this.membersTyping.map((x) => x.userFullName).join(', ');
    },
  },
  mounted() {
    this.scrollToBottom();
    if (this.showPresavedMessages) this.loadPresavedMessages();
  },
  updated() {
    this.scrollToBottom();
  },
  watch: {
    showInternalChat() {
      this.scrollToBottom();
    },
    room() {
      this.message = '';
    },
    'room.loadingMembers'() {
      this.setMembers();
    },
    hubLoaded: {
      immediate: true,
      handler(value) {
        this.subscribeToEvent(value);
      },
    },
  },
  methods: {
    loadPresavedMessages() {
      _getChatPresavedMessages(this.currentActiveLocation.locationConfigurationId).then(
        ({ data }) => (this.preservedMessages = data),
      );
    },
    presavedMessageSelect(msg) {
      RocketChatService.sendMessage(this.room.id, msg.trim());
    },
    async subscribeToEvent() {
      await this.$turnHub.$emit('joinChatGroup', this.room.id);
    },
    onFocus() {
      if (this.showMention) {
        this.room.mention = false;
      }
    },
    setMembers() {
      if (this.room.loadingMembers) return (this.members = []);
      this.members = this.room.members
        .filter(
          (x) =>
            x.username !== this.profile?.name &&
            x.userId !== this.profile?.id &&
            !this.hideMentions.includes(x.username),
        )
        .map((x) => ({
          ...x,
          value: x.userFullName,
          text: x.userFullName,
        }))
        .concat([
          {
            value: 'all',
            text: this.$t('notifyAllInRoom'),
          },
        ]);
    },
     onOpen() {
      this.selectingMember = true;
      this.setMembers();
    },
    autoresize() {
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height =
        this.$refs.textarea.scrollHeight + 'px';
    },
    async onInputChange(event) {
      await this.$turnHub.$emit('userType', this.room.id, this.currentUsername);
      this.message = event.target.value;
      this.autoresize();
      this.selectingMember = false;
    },
    getTime(date) {
      return moment(date).format('hh:mm a');
    },
    getDate(date) {
      return moment(date).format('MMM D, gggg');
    },
    showFullName(i) {
      const prevMessage = this.messages[i - 1];
      if (!prevMessage) return true;
      return this.messages[i].owner.id !== prevMessage.owner.id;
    },
    showDate(i) {
      const prevMessage = this.messages[i - 1];
      if (!prevMessage) return true;
      return moment(this.messages[i].date).isAfter(prevMessage.date, 'date');
    },
    isFirstMessage(i) {
      let value = false;
      if (this.room.msgs <= this.messages.length) {
        return i === 0;
      }

      return value;
    },
    findMember(username) {
      return this.room.members.find((x) => x.username == username);
    },
    isInfoNotification(i) {
      return this.messages[i].msg.startsWith('info-noti-');
    },
    showMessage(i) {
      const message = this.messages[i];
      if (this.isInfoNotification(i)) {
        return this.showInfoNotifications.some((x) =>
          message.msg.startsWith(x),
        );
      }
      if (message.type) {
        return !this.hideUserEntries.includes(message.msg);
      }
      return true;
    },
    getTextMessage(message) {
      if (['au', 'uj'].includes(message.type)) {
        const member = this.findMember(message.msg);
        return this.$t('userAdded', [
          `<b>${member?.userFullName || '...'}</b>`,
        ]);
      }

      if (message.type === 'ru') {
        const member = this.findMember(message.msg);
        return this.$t('userRemoved', [
          `<b>${member?.userFullName || message.msg}</b>`,
        ]);
      }
      let msg = message.msg;

      if (this.showInfoNotifications.some((x) => msg.startsWith(x))) {
        this.showInfoNotifications.forEach((noti) => {
          msg = msg.replaceAll(`${noti}-`, '');
        });
      }

      const mentions = message.mentions || [];

      for (const mention of mentions) {
        let label = '@';
        if (mention.username === 'all') {
          label += this.$t('all');
        } else if (mention.username === 'here') {
          label += this.$t('here');
        } else if (mention.name) {
          label += mention.name;
        } else {
          label += mention.username;
        }
        msg = msg.replaceAll(`@${mention.username}`, `<b>${label}</b>`);
      }

      return msg;
    },
    scrollToBottom() {
      // Use $nextTick to ensure that the DOM has been updated before scrolling
      this.$nextTick(() => {
        // Access the DOM element using $refs and scroll to the bottom
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
    handleEnter(event) {
      // Check if Shift key is pressed
      if (event.shiftKey) {
        // Insert a line break at the current cursor position
        const cursorPos = event.target.selectionStart;
        const textBefore = this.message.substring(0, cursorPos);
        const textAfter = this.message.substring(cursorPos);

        this.message = textBefore + '\n' + textAfter;

        // Move the cursor to the correct position
        this.$nextTick(() => {
          event.target.setSelectionRange(cursorPos + 1, cursorPos + 1);
          this.autoresize();
        });
      } else this.onSubmit();
    },
    onSubmit({ force, message } = { force: false, message: '' }) {
      const newMessage = message || this.message;
      if (!newMessage) return;
      const words = newMessage.split(' ');
      const lastWord = words[words.length - 1];
      if ((lastWord.includes('@') || this.selectingMember) && !force) {
        return (this.selectingMember = false);
      }
      let msg = newMessage;
      this.room.members.forEach((member) => {
        msg = msg.replaceAll(`@${member.userFullName}`, `@${member.username}`);
      });
      RocketChatService.sendMessage(this.room.id, msg.trim());
      this.message = '';
      this.$refs.textarea.style.height = '36px';
    },
  },
};
</script>
<style>
.mention-item,
.mention-no-result {
  padding: 4px 10px;
  border-radius: 4px;
}

.mention-selected {
  background: #ff8106;
}
</style>

<style scoped lang="scss">
.chat {
  // min-height: calc(100vh - 70px);
  margin-left: 86px;
  .mention {
    .chat-content,
    .chat-form {
      border: 3px solid #e41e1e;
      border-radius: 4px;
    }
  }

  .chat-content {
    min-height: calc(100vh - 190px);
    max-height: calc(100vh - 190px);
    padding: 10px 10px;
    overflow-y: auto;

    .start-conversation {
      font-weight: bold;
      text-align: center;
      margin-bottom: 5px;
    }

    .start-conversation-date {
      position: relative;
      display: flex;
      justify-content: center;
      .date {
        position: absolute;
        background-color: #ff8106;
        padding: 7px;
        color: #fff;
        border-radius: 20px;
      }
      hr {
        width: 100%;
        border-top-width: 2px;
      }
    }

    .message-content {
      padding-right: 20px;

      .message {
        border-radius: 8px;
        background-color: #feeec5;
        color: #1c1d21;
        padding: 12px;
        padding-bottom: 14px;
        margin-bottom: 8px;
        width: fit-content;
        font-size: 16px;
        position: relative;
        white-space: pre-line;
        min-width: 80px;
        .full-name {
          font-weight: bold;
          font-size: 12px;
        }
        .time {
          font-size: 10px;
          position: absolute;
          bottom: 2px;
          right: 12px;
        }
      }

      &.mine {
        padding-left: 20px;
        padding-right: 0px;
        display: flex;
        justify-content: end;
        .message {
          background-color: #e7fbd9;
        }
      }

      &.small {
        .message {
          font-size: 12px;
          padding: 10px;
          opacity: 0.7;

          .full-name {
            display: none;
          }
          .time {
            font-size: 8px;
          }
        }
      }
      &.info-noti {
        .message {
          font-size: 12px;
          padding: 10px;
          opacity: 0.7;
        }
        .full-name {
          display: none;
        }
        .time {
          font-size: 8px;
        }
      }
    }
  }
  .presaved-messages {
    .pm-container {
      display: flex;
      overflow-x: auto;
      flex-wrap: nowrap;
      padding-top: 7px;
      height: 50px !important;
      .presaved-message {
        flex: 0 0 auto;
        height: 25px;
        padding: 2px 8px;
        margin: 0 5px;
        border-radius: 25px;
        background-color: #ff8106;
        color: #fff;
        font-size: 14px;
        cursor: pointer;
        opacity: 0.6;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  .chat-typing-members {
    background-color: #ebebeb;
    height: 25px !important;
  }
  .chat-form {
    background-color: #ebebeb;
    height: 120px;
    .mentionable {
      width: 100%;
      .textarea {
        display: block;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        resize: both;
        min-height: 20px;
        max-height: 110px;
        line-height: 20px;
        background-color: white;
        border-radius: 5px;
        padding: 8px;
        font-size: 18px;
        color: #1c1d21;
        resize: none;
        border: none;
      }
    }
  }
}
</style>
